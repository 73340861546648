import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'
import ReactPixel from 'react-facebook-pixel'; 
import { slide as Menu } from 'react-burger-menu';
import { connect } from 'react-redux';
import moment from 'moment';
import localization from 'moment/locale/id';

const HeaderContainer = styled.div`
  ${tw`shadow`}
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
`

const Logo = styled.img`
  ${tw`h-12 m-0 md:h-12`};
`
const Nav = styled.nav`
  ${tw`flex items-center justify-between bg-white flex-wrap py-1 font-sans md:py-2`};
`
const LogoContainer = styled.div`
  ${tw`flex items-center flex-no-shrink text-white mr-6`};
`
const RightMenu = styled.div`
  ${tw`block flex`};
  order: 2;
  @media (min-width: 992px) {
    order: 3;
  }
`
const ButtonIcon = styled.button`
  ${tw`relative block flex items-center h-full px-3 py-2 border-transparent bg-transparent rounded text-grey-darker hover:text-black cursor-pointer`};
`

const ShoppingCartMark = styled.div`
  ${tw`bg-red w-3 h-3 absolute`};
  border-radius: 50%;
  top: 2px;
  right: 2px;
  color: white;
`
const SocMedContainer = styled.div`
  ${tw`flex items-center justify-center flex-col md:flex-row`}
`

const SocialMediaIcon = styled.button`
${tw`relative block flex items-center pl-0 md:pl-2 border-transparent bg-transparent rounded text-grey-darker hover:text-black cursor-pointer`};
`

const HamburgerIcon = styled.button`
  ${tw`block flex items-center pr-1 pl-3 my-1 ml-1 border-transparent bg-transparent font-bold text-grey-darker hover:text-black cursor-pointer lg:hidden`};
  border: none;
  border-left:solid 2px #606F7B
`
const SVGIcon = styled.svg`
  ${tw`fill-current h-5 w-5`};
`
const NavElementsContainer = styled.div`
  ${tw`w-full block flex-grow lg:flex lg:items-center lg:w-auto`};
  order: 3;
  @media (min-width: 992px) {
    order: 2;
  }
`
const NavElements = styled.div`
  ${tw`text-sm lg:flex-grow hidden lg:block`};
`
const NavLink = styled(Link)`
  ${tw`block lg:inline-block mt-0 text-grey-darker hover:text-black ml-4 no-underline`};
`

const NavAnchor = styled.a`
  ${tw`block lg:inline-block mt-0 text-grey-darker hover:text-black ml-4 no-underline`};
`

const VerticalLine = styled.div`
  ${tw`mx-2 my-1`};
  border-left: 2px solid #606F7B;
`
const RedHR = styled.hr`
  ${tw`bg-red`}
`
const SocialMediaMenu = styled.div`
  ${tw`justify-center`}
  display: flex !important
`
const HContainer = styled.div`
  ${tw`container mx-auto px-6 md:px-12 w-full`};
  padding-left:.75rem;
  padding-right:.25rem;
  @media (min-width: 567px) {
    max-width: 576px;
  }
  @media (min-width: 768px) {
    max-width: 800px;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`;
const CartImg = styled.img`
  height:30px;
  margin-bottom:0;
`
const contactClickedHandler = () => {
  ReactPixel.track('Contact')
}

const menuStyle = {
  bmBurgerButton: {
    display: 'none',
    position: 'absolute',
    width: '15px',
    height: '10px',
    right: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    display: 'none',
    background: '#373a47'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenu: {
    background: '#faea18',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    fontFamily: 'Dosis',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#686767',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    margin: '20px 0px'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  },
  zIndex: 2
}

class Header extends Component {
  state = {
    showMenu: false
  };

  componentDidMount() {
    ReactPixel.init(process.env.GATSBY_PIXEL_ID);
    moment.updateLocale('id',localization)
  }

  toggleMenu = () => {
    this.setState((state) => ({ showMenu: !state.showMenu }));
  };

  handleMenuStateChange (state) {
    this.setState({showMenu: state.isOpen})  
  }

  render() {
    const { showMenu } = this.state;

    return(
      <header id="header" className="header header-full-width">
        <div className="header-wrapper">
          <div id="masthead" className="header-main show-logo-center hide-for-sticky">
            <div className="header-inner flex-row container logo-center medium-logo-center" role="navigation">
              <div id="logo" className="flex-col logo">
                <a href="https://travelwifi.id/" title="TravelWifi - Go Further" rel="home">
                  <img width="200" height="90" src="https://travelwifi.id/wp-content/uploads/2021/03/Travel-wifi.png" className="header_logo header-logo" alt="TravelWifi" />
                  <img width="200" height="90" src="https://travelwifi.id/wp-content/uploads/2021/03/Travel-wifi-light.png" className="header-logo-dark" alt="TravelWifi" />
                </a>
                </div>
              <div className="flex-col show-for-medium flex-left">
                <ul className="mobile-nav nav nav-left"></ul>
              </div>
              <div className="flex-col hide-for-medium flex-left">
                <ul className="header-nav header-nav-main nav nav-left  nav-uppercase">
                  <li className="html header-social-icons ml-0">
                    <div className="social-icons follow-icons">
                      <a href="https://www.facebook.com/travelwifi.id/" target="_blank" data-label="Facebook" rel="noopener noreferrer nofollow" className="icon plain facebook tooltip tooltipstered">
                        <i className="icon-facebook" />
                      </a>
                      <a href="https://www.instagram.com/travelwifi_id/" target="_blank" rel="noopener noreferrer nofollow" data-label="Instagram" className="icon plain instagram tooltip tooltipstered">
                        <i className="icon-instagram" />
                      </a>
                      <a href="https://www.youtube.com/channel/UCyks-cqFE-b8fZOjuaB02nA" target="_blank" rel="noopener noreferrer nofollow" data-label="YouTube" className="icon plain youtube tooltip tooltipstered">
                        <i className="icon-youtube" />
                      </a>
                    </div>
                  </li>
                  <li className="header-divider"></li>
                  <li className="html header-button-1">
                    <div className="header-button">
                      <a href="https://travelwifi.id/reseller" className="button primary is-medium" style={{borderRadius:"99px"}}>
                        <span>Reseller</span>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="flex-col hide-for-medium flex-right">
                <ul className="header-nav header-nav-main nav nav-right  nav-uppercase">
                <li className="nav-icon has-icon">
                    <div className="header-button">
                      <a href="/cart" className="icon button circle is-small current-lightbox-clicked">
                        <i className="icon-shopping-cart" />
                      </a>
                    </div>
                  </li>
                  <li className="nav-icon has-icon">
                    <div className="header-button">
                      <a href="#" data-open="#main-menu" data-pos="center" data-bg="main-menu-overlay" data-color="" className="icon primary button circle is-small current-lightbox-clicked" aria-label="Menu" aria-controls="main-menu" aria-expanded="false">
                        <i className="icon-menu" />
                      </a>
                    </div>
                  </li>
                  <li className="header-divider"></li>
                  <li className="html custom html_topbar_left">
                    <p data-wp-editing="1">
                      <a href="https://wifirepublic.id/">
                        <img className="alignnone wp-image-206" src="https://travelwifi.id/wp-content/uploads/2021/04/former.png" alt="" width="55" height="40" />
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="flex-col show-for-medium flex-right">
                <ul className="mobile-nav nav nav-right ">
                    <div className="header-button">
                      <a href="/cart" className="icon button circle is-small current-lightbox-clicked">
                        <i className="icon-shopping-cart" />
                      </a>
                    </div>
                  <li className="nav-icon has-icon">
                    <div className="header-button">
                      <a href="#" data-open="#main-menu" data-pos="center" data-bg="main-menu-overlay" data-color="" className="icon primary button circle is-small" aria-label="Menu" aria-controls="main-menu" aria-expanded="false">
                        <i className="icon-menu" />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="header-bg-container fill">
            <div className="header-bg-image fill"></div>
            <div className="header-bg-color fill"></div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  shoppingCart: state.shoppingCart
});

export default connect(mapStateToProps)(Header);
