import React from 'react';

import Pay1 from '../../static/images/svgs/pay1.svg';
import Pay2 from '../../static/images/svgs/pay2.svg';
import Pay3 from '../../static/images/svgs/pay3.svg';
import Pay4 from '../../static/images/svgs/pay4.svg';
import Pay5 from '../../static/images/svgs/pay5.svg';

import 'typeface-open-sans';

const Footer = () => (
  <footer id="footer" className="footer-wrapper" style={{backgroundColor:'white'}}>
    <section className="section" id="section_2010464699">
      <div className="bg section-bg fill bg-fill bg-loaded"></div>
      <div className="section-content relative">
        <div className="row align-center" style={{maxWidth:"90%"}} id="row-1437702157">
          <div id="col-805934128" className="col medium-4 small-12 large-4">
            <div className="col-inner">
              <div className="img has-hover x md-x lg-x y md-y lg-y" id="image_1732912276"> 
                <a className="" href="https://travelwifi.id">
                  <div className="img-inner dark" style={{margin:"0px 0px 32px 0px"}}> 
                    <img width="200" height="51" src="https://travelwifi.id/wp-content/uploads/2021/03/travelwifilogo.png" className="attachment-original size-original" alt="Logo TravelWifi Go Further" loading="lazy" style={{marginBottom:0}} />
                  </div>
                </a>
              </div>
              <div id="text-635788822" className="text">
                <p>
                  <span style={{fontSize:"90%"}}>
                    <span style={{color:"#000000"}}>
                      Epicentrum Walk, Lantai 6 Unit B636,<br/>
                      Jl. H. R. Rasuna Said, Karet Kuningan, <br/>
                      Jakarta Selatan, 12940<br/>
                      Telp. 021 - 29101008
                    </span>
                  </span>
                </p>
              </div>
              <div className="social-icons follow-icons" style={{fontSize:"89%"}}>
                <a href="https://www.facebook.com/travelwifi.id/" target="_blank" data-label="Facebook" rel="noopener noreferrer nofollow" className="icon primary button circle facebook tooltip tooltipstered">
                  <i className="icon-facebook" />
                </a>
                <a href="https://www.instagram.com/travelwifi_id/" target="_blank" rel="noopener noreferrer nofollow" data-label="Instagram" className="icon primary button circle instagram tooltip tooltipstered">
                  <i className="icon-instagram" />
                </a>
                <a href="https://www.youtube.com/channel/UCyks-cqFE-b8fZOjuaB02nA" target="_blank" rel="noopener noreferrer nofollow" data-label="YouTube" className="icon primary button circle youtube tooltip tooltipstered">
                  <i className="icon-youtube" />
                </a>
              </div>
            </div>
          </div>
          <div id="col-1972408794" className="col medium-5 small-12 large-5">
            <div className="col-inner text-left">
              <div id="gap-1143922746" className="gap-element clearfix show-for-small" style={{display:"block",height:"auto"}}></div>
              <div id="text-2627553012" className="text">
                <h4 className="page-heading"><span style={{color:"#000000",fontSize:"110%"}}>Kami siap membantu Anda 24/7</span>
                </h4>
              </div>
              <div id="gap-952215035" className="gap-element clearfix" style={{display:"block",height:"auto"}}></div>
              <div className="row" id="row-1664665150">
                <div id="col-36790814" className="col medium-6 small-6 large-6">
                  <div className="col-inner text-left">
                    <div id="text-2664117041" className="text"> <strong><span style={{color:"#000000"}}>SUPPORT CENTER</span></strong>
                    </div>
                    <div className="is-divider divider clearfix" style={{maxWidth:"100%",height:"1px"}}></div> <a
                      rel="noopener noreferrer" href="https://mauorder.online/halo-travelwifi" target="_blank"
                      className="button success is-shade is-smaller lowercase expand" style={{borderRadius:"99px"}}>
                      <span>WhatsApp</span> <i className="icon-phone"></i></a>
                  </div>
                </div>
                <div id="col-1718364411" className="col medium-6 small-6 large-6">
                  <div className="col-inner text-left">
                    <div id="text-2345571835" className="text"> <strong><span style={{color:"#000000"}}>RESELLER PROGRAM</span></strong>
                    </div>
                    <div className="is-divider divider clearfix" style={{maxWidth:"100%",height:"1px"}}></div> <a
                      rel="noopener noreferrer"
                      href="https://api.whatsapp.com/send?phone=6281212850921&amp;text=Hai%20Min%2C%20Mau%20tanya-tanya%20mengenai%20program%20Reseller%20dong"
                      target="_blank" className="button success is-shade is-smaller lowercase expand" style={{borderRadius:"99px"}}>
                      <span>WhatsApp</span> <i className="icon-phone"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="col-562698890" className="col medium-3 small-12 large-3">
            <div className="col-inner">
              <h4 className="page-heading">
                <span style={{color:"#000000", fontSize: "100%"}}>
                  <span style={{fontSize:"75%"}}>Formerly:</span>
                </span>
              </h4>
              <div className="img has-hover x md-x lg-x y md-y lg-y" id="image_2089156738">
                <a className="" href="https://wifirepublic.id/" target="_blank" rel="noopener noreferrer">
                  <div className="img-inner image-color dark"> 
                    <img width="296" height="156" src="https://travelwifi.id/wp-content/uploads/2021/04/not-former.png"
                      className="attachment-original size-original" alt="Logo Wifi Republic" loading="lazy" />
                    </div>
                </a>
              </div>
              <h4 className="page-heading">
                <span style={{color:"#000000", fontSize: "100%"}}>
                  <span style={{fontSize:"75%"}}>Global Website:</span><br/>
                </span>
              </h4>
              <h4>
                <strong><span style={{color:"#6a0bb3"}}><a style={{color:"#6a0bb3"}} href="https://travelwifi.com">travelwifi.com</a></span></strong>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="absolute-footer light medium-text-center text-center">
      <div className="container clearfix">
        <div className="footer-secondary pull-right">
          <div className="payment-icons inline-block">
            <div className="payment-icon">
              <Pay1 />
            </div>
            <div className="payment-icon">
              <Pay2 />
            </div>
            <div className="payment-icon">
              <Pay3 />
            </div>
            <div className="payment-icon">
              <Pay4 />
            </div>
            <div className="payment-icon">
              <Pay5 />
            </div>
          </div>
        </div>
        <div className="footer-primary pull-left">
          <div className="copyright-footer"> Copyright 2022 © <strong>TravelWifi</strong>
            <p><a href="https://travelwifi.id/syarat-dan-ketentuan-travelwifi/">
              <span style={{fontSize:"9.96px"}}>Syarat dan Ketentuan</span>
            </a></p>
          </div>
        </div>
      </div>
    </div>
    {/* <a href="#top" className="back-to-top button icon invert plain fixed bottom z-1 is-outline circle active"
      id="top-link"><i className="icon-angle-up"></i></a>   */}
  </footer>
);

export default Footer;