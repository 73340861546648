import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './header';
import Footer from './footer';
import './layout.css';
import 'typeface-dosis';
import 'typeface-open-sans';

const Content = styled.div`
  ${tw`px-0 pt-0 pb-0 font-sans`};
  flex-grow:1;
  overflow-x:hidden;
  background-color: #fff;
`
const WaLink = styled.a`
  ${tw`fixed inline-flex items-center p-2 px-2 rounded-full no-underline px-4`};
  right:5rem;
  bottom:1rem;
  color:white;
  background-color:#55bd22;
  border-radius: 1.5rem;
  z-index: 10004;
  @media screen and (min-width:760px){
    right:4.5rem;
    bottom:.75rem;
  }
  @media screen and (min-width:1030px){
    right:7.5rem;
    bottom:.75rem;
  }
`
const WaLogo = styled.img`
  ${tw`m-0 inline-flex w-4 h-4 sm:w-4 sm:h-4 xl:w-6 xl:h-6`}
`
const WaText = styled.span`
  ${tw`text-white font-medium font-sans text-sm ml-2 xl:inline-flex`};
  
`
const AllContainer = styled.div`
  display:flex;
  min-height:100vh;
  flex-direction:column;
`
const Layout = (props) => {
  return (
    <div>
      <AllContainer>
        <Header />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover={false}
        />
        <Content>{props.children}</Content>
        <Footer />
      </AllContainer>
      <div id="main-menu" className="mobile-sidebar no-scrollbar mfp-hide">
        <div className="sidebar-menu no-scrollbar text-center">
          <ul className="nav nav-sidebar nav-vertical nav-uppercase nav-anim">
            <li id="menu-item-295" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-295">
              <a href="https://www.travelwifi.id/#intro">Why TravelWifi?</a>
            </li>
            <li id="menu-item-296" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-296 has-child" aria-expanded="false">
              <a>Order TravelWifi</a>
              <button className="toggle">
                <i className="icon-angle-down"></i>
              </button>
              <ul className="sub-menu nav-sidebar-ul children">
                <li id="menu-item-1294" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1294">
                  <a href="/products/internetindonesia/">Internet Indonesia</a>
                </li>
                <li id="menu-item-1295" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1295">
                  <a href="/net_package/subscribe/">Top Up Data Internet Indonesia</a>
                </li>
                <li id="menu-item-1811" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1811">
                  <a href="/products/internetluarnegeri/">Internet Luar Negeri</a>
                </li>
              </ul>
            </li>
            <li id="menu-item-1534" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1534">
              <a href="https://travelwifi.id/true-unlimited/">True Unlimited</a>
            </li>
            <li id="menu-item-480" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-480 has-child" aria-expanded="false">
              <a href="https://travelwifi.id/reseller/">Reseller Program</a>
              <button className="toggle">
                <i className="icon-angle-down"></i>
              </button>
              <ul className="sub-menu nav-sidebar-ul children">
                <li id="menu-item-481" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-481">
                  <a href="https://travelwifi.id/reseller/#daftar">Gabung Sekarang</a>
                </li>
                <li id="menu-item-482" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-482">
                  <a href="/products/reseller/">Top Up</a>
                </li>
              </ul>
            </li>
            <li id="menu-item-622" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-622">
              <a href="https://travelwifi.id/partnership/">Partnership</a>
            </li>
            <li id="menu-item-859" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-859">
              <a href="https://travelwifi.id/promo/">Promo</a>
            </li>
            <li id="menu-item-1214" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-1214 has-child" aria-expanded="false">
              <a href="#">Blog &amp; Ceritravi</a>
              <button className="toggle">
                <i className="icon-angle-down"></i>
              </button>
              <ul className="sub-menu nav-sidebar-ul children">
                <li id="menu-item-1213" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1213">
                  <a href="https://travelwifi.id/updates/">Blog</a>
                </li>
                <li id="menu-item-1212" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1212">
                  <a href="https://travelwifi.id/ceritravi">Ceritravi</a>
                </li>
              </ul>
            </li>
            <li className="html header-button-2">
              <div className="header-button"> 
                <a href="https://www.travelwifi.id/order" className="button success" style={{borderRadius:"99px"}}>
                  <span>Order Sekarang</span>
                </a>
              </div>
            </li>
            <li className="header-divider"></li>
            <li className="html header-social-icons ml-0">
              <div className="social-icons follow-icons">
                <a href="https://www.facebook.com/travelwifi.id/" target="_blank" data-label="Facebook" rel="noopener noreferrer nofollow" className="icon plain facebook tooltip tooltipstered">
                  <i className="icon-facebook"></i>
                </a>
                <a href="https://www.instagram.com/travelwifi_id/" target="_blank" rel="noopener noreferrer nofollow" data-label="Instagram" className="icon plain instagram tooltip tooltipstered">
                  <i className="icon-instagram"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCyks-cqFE-b8fZOjuaB02nA" target="_blank" rel="noopener noreferrer nofollow" data-label="YouTube" className="icon plain youtube tooltip tooltipstered">
                  <i className="icon-youtube"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const mapDispatchToProps = dispatch => ({
  changeReferralCode: (code) => ({ type: 'CHANGE_REFERRAL_CODE', code })
});

export default connect(null, mapDispatchToProps)(Layout);
